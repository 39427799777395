
export const addressToDisplayName = (ad, type, displayNumber) => {
  let name = []
  if(type !== "postcode") {
    if (type === "house" || type==="node") {
      if (displayNumber) {
        name.push(`${ad.road} ${ad.house_number}`)
      } else {
        name.push(`${ad.road}`)
      }
    } else {
      name.push(ad[Object.keys(ad)[0]])
    }
  }

  if(ad?.suburb){
    name.push(ad.suburb)
  } else if (ad?.neighbourhood) {
    name.push(ad?.neighbourhood)
  } else if (ad?.quarter) {
    name.push(ad?.quarter)
  }

  if(ad?.postcode) {
    name.push(ad.postcode)
  }

  let city = ad?.city? ad?.city: (ad?.town? ad?.town: (ad?.village? ad?.village: ad?.municipality))
  if (city) {
    name.push(city)
  }

  return name.join(", ")
}
