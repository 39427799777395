import {useEffect, useState} from 'react';
import {getViewbox, getAddressOptions} from './getAddressOptions';
import {useRouter} from "next/router";
import Link from 'next/link'
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {BiCurrentLocation} from "react-icons/bi";
import {FormattedMessage} from "react-intl";

const LoadingIndicator = () => {
  return <div className={"p-2 pt-4 bg-slate-100 grid grid-cols-1 divide-y rounded-b"}>
    <div key="no-address" className={"p-1 pt-2 pb-2 text-gray-700 font-semibold flex items-center gap-2"}>
      <AiOutlineLoading3Quarters className={"animate-spin"}/>
      <div><FormattedMessage
        description="Loading"
        defaultMessage="Loading"
      /></div>
    </div>
  </div>
}

function AddressSearchForm() {
  const [value, setValue] = useState("")
  const [addresses, setAddresses] = useState([{
    "display_name": <FormattedMessage
      description="No address found"
      defaultMessage="No address found"
    />,
    "fake": true
  }])
  const [locAvailable, setLocAvailable] = useState(false)
  const [locationInfo, setLocationInfo] = useState({
    "city": null,
    "box": null,
  })
  const [geoError, setGeoError] = useState(null)
  const [loading, setLoading] = useState(false)


  const handleSubmit = event => {
    event.preventDefault() // don't redirect the page
    // where we'll add our form logic
  }
  const handleChange = e => {
   setValue(e.target.value)
  }

  const router = useRouter()

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude.toFixed(4);
      let lon = position.coords.longitude.toFixed(4);
      router.push(`/results?lat=${lat}&lon=${lon}`)
    }, (error) => {
      setGeoError(<FormattedMessage
        description="Location retrieval failed"
        defaultMessage="Failed to retrieve location 😔 Did we get your permission?"
      />)
    })
  }

  useEffect(() => {
    getViewbox().then(r => {
      setLocationInfo(r)
    })
  }, []);

  useEffect(() =>{
    setLocAvailable((typeof window !== 'undefined' && window.navigator && "geolocation" in window.navigator))
  }, [])


  useEffect(() => {
    // wait for the useRouter hook to asynchronously get the query id
    if (!value) {
      setLoading(false)
      return;
    }
    const abortController = new AbortController();
    setLoading(true)
    let timer1 = setTimeout(() => {
      getAddressOptions(value, abortController.signal, locationInfo).then((response) => {
        if (response.length > 0) {
          setAddresses(response)
        } else{
          setAddresses([{
            "display_name": <FormattedMessage
              description="No address found"
              defaultMessage="No address found"
            />,
            "fake": true
          }])
        }
        setLoading(false)
      }).catch((e) => {
        setLoading(false)
        console.log(e)
      })
    },  300);


    return () => {
      clearTimeout(timer1);
      abortController.abort();
    }

  }, [value, locationInfo]);

  return (
    <div className="">
      <div className="max-w-md mx-auto">
        <div className={"grid grid-cols-1 gap-3"}>
          <div>
            <form onSubmit={handleSubmit}>
              <label className={"block text-gray-700 text-sm font-bold mb-2"}><FormattedMessage
                description="Address input title"
                defaultMessage="Postcode, Address or anything you can think of"
              /></label>
              <input type="text" onChange={handleChange} value={value} className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}/>
            </form>
            {!loading ?
              <div className={"p-2 pt-4 bg-slate-100 grid grid-cols-1 divide-y rounded-b"}>
                {addresses.map((address) => {
                  if (address.fake) {
                    return <div key="no-address" className={"p-1 pt-2 pb-2 text-gray-700 font-semibold"}>
                      {address.display_name}
                    </div>
                  } else {
                    return <div key={address.place_id}
                                className={"p-1 pt-2 pb-2 hover:bg-slate-50 hover:underline text-gray-700 font-semibold"}>
                      <Link href={"/results?lat=" + (parseFloat(address.lat).toFixed(4)) + "&lon=" + parseFloat(address.lon).toFixed(4) + "&disp_address=" + encodeURIComponent(address.filtered_name)}>{address.filtered_name}</Link>
                    </div>
                  }
                })}
              </div> :
             <LoadingIndicator />
            }
          </div>

            <div className={"grid justify-items-end"}>
              {locAvailable &&
                <div className={"grid justify-items-end gap-2"}>
                  <button type="button"
                          className="bg-indigo-500 text-white px-3 py-2 font-semibold shadow text-sm rounded-md flex items-center gap-1"
                          onClick={getUserLocation}>
                    <BiCurrentLocation/>
                    <p><FormattedMessage
                      description="Button use device location"
                      defaultMessage="Directly search with my current location"
                    /></p>
                  </button>
                  {geoError && <div className={"bg-red-100 border-red-300 rounded-md px-4 py-2 text-red-800 font-medium border"}>
                    {geoError}
                    </div>
                  }
                </div>
              }
            </div>
        </div>
      </div>
    </div>
  );
}

export default AddressSearchForm
