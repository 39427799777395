import Link from 'next/link'
import {FormattedMessage} from "react-intl";
import {useRouter} from "next/router";

export default function LanguagePicker() {
  const router = useRouter()

  const switchLanguage = (language) => {
    let expireTime = 60*60*24*365; // 1 year
    document.cookie = `NEXT_LOCALE=${language};max-age=${expireTime};SameSite=Strict;path=/`;
    router.push(router.asPath, undefined, { locale: language })
  }

  const buttonClasses="hover:underline"

  return  <div className={"flex justify-center gap-3 flex-wrap"}>
    <Link href={router.asPath} locale={"en"}>
      <a className={buttonClasses} onClick={(e) => {
        e.preventDefault()
        switchLanguage("en")
      }
      }>
        English
      </a>
    </Link>

    <Link href={router.asPath} locale={"nl"}>
    <a className={buttonClasses} onClick={(e) => {
      e.preventDefault()
      switchLanguage("nl")
    }
    }>
      Nederlands
    </a>
    </Link>
  </div>
}


