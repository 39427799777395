import Link from 'next/link'

const BeerIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
              preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36" className={"w-12 h-full -ml-1"}>
    <path fill="#FFAC33"
          d="M31 5.718h-6v4h4s2 0 2 2v12c0 2-2 2-2 2h-4v4h6c2.206 0 4-1.794 4-4v-16c0-2.206-1.794-4-4-4z"/>
    <path fill="#FFCC4D" d="M27 6H3v26a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V6z"/>
    <path fill="#F4900C"
          d="M8.5 32a1 1 0 0 1-1-1V15a1 1 0 0 1 2 0v16a1 1 0 0 1-1 1zm6.5 0a1 1 0 0 1-1-1V15a1 1 0 0 1 2 0v16a1 1 0 0 1-1 1zm6.5 0a1 1 0 0 1-1-1V15a1 1 0 1 1 2 0v16a1 1 0 0 1-1 1z"/>
    <path fill="#FFAC33"
          d="M3 5v7.445c.59.344 1.268.555 2 .555a4 4 0 0 0 3.701-2.491c.35.302.801.491 1.299.491c.677 0 1.273-.338 1.635-.853A3.988 3.988 0 0 0 15 12a3.98 3.98 0 0 0 3.176-1.593A2.496 2.496 0 0 0 20.5 12c.949 0 1.765-.535 2.188-1.314l.147-.361a3.463 3.463 0 0 0 1.665.439c.981 0 1.865-.406 2.5-1.056V5H3z"/>
    <path fill="#EEE"
          d="M24 0H4a3 3 0 0 0-3 3v4a4 4 0 0 0 7.701 1.509C9.051 8.811 9.502 9 10 9c.677 0 1.273-.338 1.635-.853A3.988 3.988 0 0 0 15 10a3.98 3.98 0 0 0 3.176-1.593A2.496 2.496 0 0 0 20.5 10c.949 0 1.765-.535 2.188-1.314c.398.195.839.314 1.312.314a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z"/>
  </svg>
}

const FlashIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
              preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36" className={"w-12 h-full"}>
    <path fill="#FFAC33"
          d="M32.938 15.651A1.002 1.002 0 0 0 32 15H19.925L26.89 1.458A.999.999 0 0 0 26 0a1 1 0 0 0-.653.243L18 6.588L3.347 19.243A1 1 0 0 0 4 21h12.075L9.11 34.542A.999.999 0 0 0 10 36a1 1 0 0 0 .653-.243L18 29.412l14.653-12.655a1 1 0 0 0 .285-1.106z"/>
  </svg>
}

export default function Logo({style}) {

  return <Link href={"/"} passHref={true}>
    <div className="text-6xl font-bold text-center flex justify-center gap-2 content-center cursor-pointer" style={style}>
      <div className={"relative flex gap-2"}>
        <div className={"flex"}>
          <FlashIcon/>
          <BeerIcon /></div>
        <div className={"hover:underline decoration-3 static"}>
          Flitspils
        </div>
        <div
          className={"origin-bottom -rotate-[26deg] -right-10 -bottom-3 text-lg tracking-wider absolute from-pink-500 to-yellow-500 bg-gradient-to-r uppercase rounded-md text-white text-semibold px-2 py-1"}>demo
        </div>
      </div>
    </div>
  </Link>
}
