import {addressToDisplayName} from "@/components/address_search/formatAddress";


export const getAddressOptions = async (query, signal, locationInfo) => {
  const serialize = function(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  let url = "https://nominatim.openstreetmap.org/search.php"

  // remove whitespace from postalcode
  query = query.replace(/([0-9]{4}) +([a-zA-Z]{2})/g, "$1$2")

  let query_str = {
    "q": query,
    "dedupe":1,
    crossDomain:true,
    "accept-language": "nl",
    "countrycodes":"nl",
    "addressdetails": 1,
    "format":"json",
    "limit": 10
  };
  if(locationInfo && locationInfo["box"]) {
    query_str["viewbox"] = locationInfo["box"]
  }
  let response = await fetch(url + "?" + serialize(query_str), {
    method: 'GET',
    signal: signal,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  response = await response.json()

  // the display name is something like "Looiersgracht, Jordaan, Centrum, Amsterdam, Noord-Holland, Nederland, 1016WC, Nederland"
  // that's way too much
  // let's rewrite
  for (let i =0; i < response.length; i++) {
    let ad = response[i]?.address
    // unfortunately there is not always a "street name" since it also might be a canal or buidling
    response[i]["filtered_name"] = addressToDisplayName(ad,  response[i].type,true)
  }

  return response
}

export let getViewbox = async () => {
  let box = null;
  let city = null;
  try {

    let response = await fetch("/api/address/getViewBox")
    response = await response.json()

    box = response['box']
    city = response['city']
  } catch(error){
    console.log(error)
  }

  return {
    "box": box,
    "city": city
  }
}
