import Head from 'next/head'
import AddressSearchForm from "@/components/address_search/AddressSearchForm";
import Logo from "@/components/general/Logo";
import Footer from "@/components/general/Footer";
import React from 'react'
import {useIntl} from 'react-intl'
import LanguagePicker from "@/components/general/LanguagePicker";
import loadI18nMessages from "@/components/general/load_int_messages";

export async function getStaticProps(ctx) {
  return {
    props: {
      intlMessages: await loadI18nMessages(ctx),
    },
  }
}

export default function Home({box}) {
  const intl = useIntl()
  return (
    <div>
      <Head>
        <title>FlitsPils</title>
        <meta name="description" content={intl.formatMessage(
          {
            description:"Header description",
            defaultMessage:"Find the best flash delivery options"
          })}/>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="p-5 container mx-auto min-h-screen justify-centerc">
        <div>
         <Logo />
        </div>
        <div className={"mt-10"}>
          <AddressSearchForm />
        </div>
        <div className={"mt-10"}>
          <LanguagePicker />
        </div>
      </div>
      <Footer />
    </div>
  )
}
