import Link from 'next/link'
import {AiFillCaretDown} from "react-icons/ai";
import {FormattedMessage} from "react-intl";
import LanguagePicker from "@/components/general/LanguagePicker";

export default function Footer() {
  return  <div className={"bg-slate-100 -mt-5"}>
    <div className={"p-5 container mx-auto"}>
      <div className={"-mt-7 "}>
        <AiFillCaretDown className={"mx-auto text-gray-500"} />
      </div>
        <div className={"mt-4 text-lg font-bold text-gray-500"}>
          <FormattedMessage description="Footer Title" defaultMessage=" What is ⚡️🍺 Flitspils?"/>
        </div>
      <div className="mt-2 font-normal text-gray-600 font-medium">

        <FormattedMessage description="Footer Text" defaultMessage="As you might have noticed, this website is a demo to showcase what Flitspils could be. The aim is to be homepage for all late-night orders of beer, food, cabs or anything else. At these types of moments, people certainly do not want to spend time finding the best option. We try to make that personalised choice for them in advance. We strive to offer more than a simple comparison and rather offer fast and ready solutions for their needs. As a starting point, we focus on flash deliveries in Netherlands of alcoholic beverages with suppliers like Flink, Gorilla's and Zapp. However, we aim to extend to other segments as well. Do you have questions, feedback, or just want to talk? You can reach us at"/>

        <span className={"font-semibold hover:underline"} dangerouslySetInnerHTML={{__html: "<a href=\"javascript:window.location.href=atob('bWFpbHRvOmluZm9AZmxpdHNwaWxzLm5s')\"> info [at] flitspils.nl</a>"}}/>
          <span> <FormattedMessage description={"or"} defaultMessage={"or"}/> </span>
          <Link href={"/feedback"}>
            <a className={"font-semibold hover:underline"}> <FormattedMessage description={"here"} defaultMessage={"here"}/></a>
            </Link>.
      </div>
      <div className={"mt-7"}>
        <LanguagePicker />
      </div>
    </div>
  </div>
}


